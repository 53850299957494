<template>
  <div>
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadPasses"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null, 'pass')"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        v-model="tableSelectedData"
        :headers="tableColumns"
        :items="tableList"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- title -->
        <template #[`item.title`]="{item}">
          {{ t(item.title) }}
          <v-chip
            v-if="item.trial"
            color="success"
            small
          >
            TRIAL
          </v-chip>
          <v-chip
            v-if="item.bulk"
            color="info"
            small
          >
            BULK
          </v-chip>
        </template>

        <!-- distributor -->
        <template #[`item.distributor_id`]="{item}">
          {{ item.distributor.nicename }}
        </template>

        <!-- interval_count -->
        <template #[`item.interval_count`]="{item}">
          <div>
            <span class="mr-1">{{ item.interval_count }}</span>
            <span class="mr-1">{{ item.interval_unit }}</span>
            <span v-if="item.delay_days">(+{{ item.delay_days }} days)</span>
          </div>
        </template>

        <!-- coupon_code -->
        <template #[`item.coupon_code`]="{item}">
          {{ item.coupon_code }}
        </template>

        <!-- parent_id -->
        <template #[`item.parent_id`]="{item}">
          <span v-if="item.parent_id">{{ t(item.parent.title) }} (#{{ item.parent.id }})</span>
        </template>

        <!-- parent_id -->
        <template #[`item.layout`]="{item}">
          <span v-if="getLayout(item)">
            <img
              :src="getLayout(item).instance.thumbnail"
              width="120"
            >
          </span>
          <v-chip
            v-else
            color="error"
          >
            Layout Required!
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item, 'pass')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="setAside(item, 'card_layout')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                    </v-icon>
                    <span>Update Layout</span>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                  link
                  @click="deletePass(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <pass-aside
      v-if="aside === 'pass'"
      v-model="asideActive"
      :resource="resource"
      @aside="val => { if (!val) { aside = '' } }"
      @changed="changedPass"
    ></pass-aside>

    <card-layout-aside
      v-if="resource && aside === 'card_layout'"
      v-model="asideActive"
      :resource="resource"
      @changed="loadPasses(); resource = null; asideActive = false"
    >
    </card-layout-aside>
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'

import CardLayoutAside from '@/modules/medical/views/card-layout-resource/CardLayoutAside.vue'
import PassAside from '../pass-resource/PassAside.vue'
import usePassList from './usePassList'

export default {
  components: {
    PassAside,
    CardLayoutAside,
  },

  setup() {
    const {
      tableList, tableColumns, tableTotal, tableSelectedData, searchQuery, emailQuery, options, loadPasses, deletePass, loading,
    } = usePassList()

    const asideActive = ref(false)
    const resource = ref(null)
    const aside = ref(null)
    const setAside = (item, type) => {
      asideActive.value = true
      aside.value = type
      resource.value = item
    }

    const getLayout = item => item.layouts[item.layouts.length - 1]

    const changedPass = pass => {
      loadPasses()
      if (!resource.value || !resource.value.layouts.length) {
        resource.value = pass
        aside.value = 'card_layout'
      } else {
        aside.value = ''
        asideActive.value = false
      }
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      searchQuery,
      emailQuery,

      loadPasses,
      deletePass,

      setAside,
      asideActive,
      aside,
      resource,

      options,
      loading,

      t,
      getLayout,
      changedPass,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
