var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "value": _vm.active,
      "permanent": "",
      "temporary": "",
      "touchless": "",
      "right": !_vm.$vuetify.rtl,
      "width": _vm.$vuetify.breakpoint.smAndUp ? 580 : '100%',
      "app": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('aside', val);
      }
    }
  }, [_c('v-container', [_c('a', {
    on: {
      "click": function click($event) {
        return _vm.$emit('aside', false);
      }
    }
  }, [_vm._v("Back")])]), _c('pass-form', {
    attrs: {
      "resource": _vm.resource,
      "loading": _vm.loading,
      "errors": _vm.errors
    },
    on: {
      "submit": _vm.handleSubmit
    }
  }), _c('div', {
    staticClass: "p-2 mt-2"
  }, [_vm._v(" Note: Define \"Parent Pass\" in these ways: "), _c('ul', [_c('li', [_vm._v("1. Free trial's recommend upgrade-to pass.")]), _c('li', [_vm._v("Free trial is $299 with 6 months, offers a free-trial coupon on this pass")]), _c('li', [_vm._v("The "), _c('b', [_vm._v("Parent Customer Pass")]), _vm._v(" is another pass $299 with 12 months")]), _c('li', [_vm._v("---")]), _c('li', [_vm._v("2. Agent customer's next-recursion pass.")]), _c('li', [_vm._v("Agent Pass can be bulk-purchased at $249 at min_quantity=5+, or $229 at min_quantity=10+")]), _c('li', [_vm._v("The customer won't be able to pay $249 for 5 quantity, he just need 1 quantity with $299.")]), _c('li', [_vm._v("Set its "), _c('b', [_vm._v("Parent Customer Pass")]), _vm._v(" to a later auto-charged with $299 min_quantity=1.")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }