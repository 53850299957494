import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { destroyPass, fetchPasses } from '@api/product/pass'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useList() {
  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Title', value: 'title', sortable: false },
    { text: 'Distributor', value: 'distributor_id' },
    { text: 'Parent', value: 'parent_id' },
    { text: 'Layout', value: 'layout', sortable: false },
    { text: 'PRICE (REF)', value: 'selling_price' },
    { text: 'INTERVAL', value: 'interval_count' },
    { text: 'COUPON', value: 'coupon_code' },
    { text: 'QUOTA', value: 'card_quota' },
    { text: 'SORT', value: 'sort' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const emailQuery = ref(cached[2] || '')
  const tableTotal = ref(0)
  const loading = ref(false)
  const tableSelectedData = ref([])

  const loadPasses = () =>
    fetchPasses(
      useTableOptions(options.value, {
        search: searchQuery.value,
        email: emailQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        tableList.value = records
        tableTotal.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const deletePass = pass =>
    destroyPass(pass.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadPasses()
      })
      .catch(useNotifyErrors)

  const cachable = [options, searchQuery, emailQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    tableSelectedData.value = []
    loadPasses()
    cache()
  })

  return {
    tableColumns,
    searchQuery,
    emailQuery,
    options,
    tableList,
    tableTotal,
    loading,
    tableSelectedData,
    loadPasses,
    deletePass,
  }
}
