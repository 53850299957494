<template>
  <v-navigation-drawer
    :value="active"
    permanent
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 580 : '100%'"
    app

    @input="(val) => $emit('aside', val)"
  >
    <v-container>
      <a @click="$emit('aside', false)">Back</a>
    </v-container>

    <pass-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />

    <div class="p-2 mt-2">
      Note: Define "Parent Pass" in these ways:
      <ul>
        <li>1. Free trial's recommend upgrade-to pass.</li>
        <li>Free trial is $299 with 6 months, offers a free-trial coupon on this pass</li>
        <li>The <b>Parent Customer Pass</b> is another pass $299 with 12 months</li>
        <li>---</li>
        <li>2. Agent customer's next-recursion pass.</li>
        <li>Agent Pass can be bulk-purchased at $249 at min_quantity=5+, or $229 at min_quantity=10+</li>
        <li>The customer won't be able to pay $249 for 5 quantity, he just need 1 quantity with $299.</li>
        <li>Set its <b>Parent Customer Pass</b> to a later auto-charged with $299 min_quantity=1.</li>
      </ul>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import store from '@/store'
import { storePass, updatePass } from '@api/product/pass'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import PassForm from './PassForm.vue'

export default {
  components: { PassForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const request = props.resource ? updatePass(props.resource.id, { ...form }) : storePass({ ...form })
      await request
        .then(async res => {
          await store.dispatch('product/fetchAllPasses')
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
