var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('translation-locales'), _c('v-card', [_c('v-card-text', [_c('v-select', {
    attrs: {
      "label": "SKU (associated from Core)",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.skuOptions,
      "rules": [_vm.required(_vm.form.gateway_sku_id)]
    },
    on: {
      "input": _vm.skuChanged
    },
    model: {
      value: _vm.form.gateway_sku_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gateway_sku_id", $$v);
      },
      expression: "form.gateway_sku_id"
    }
  }), _c('p', [_vm._v("Subscription type: "), _c('b', [_vm._v(_vm._s(_vm.form.type))]), _vm._v(" (controlled by core SKU -> plan)")]), _c('p', [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.form.interval_count))]), _c('span', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.form.interval_unit))]), _vm.form.delay_days ? _c('span', [_vm._v("(+" + _vm._s(_vm.form.delay_days) + " days)")]) : _vm._e()]), _c('v-row', [_c('v-col', {
    attrs: {
      "col": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Owner type",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.ownerOptions
    },
    model: {
      value: _vm.form.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "owner", $$v);
      },
      expression: "form.owner"
    }
  })], 1)], 1), _c('v-autocomplete', {
    attrs: {
      "label": "Distributor",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.distributorOptions,
      "rules": [_vm.between(_vm.form.distributor_id, 1, 999999999)]
    },
    model: {
      value: _vm.form.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_id", $$v);
      },
      expression: "form.distributor_id"
    }
  }), _c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Title",
      "outlined": ""
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }), _c('translatable-editor', {
    staticClass: "mt-5",
    attrs: {
      "label": "Content",
      "outlined": ""
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Reference Selling Price",
      "outlined": "",
      "disabled": ""
    },
    model: {
      value: _vm.form.selling_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "selling_price", $$v);
      },
      expression: "form.selling_price"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Card Quota (controlled by Core)",
      "outlined": "",
      "disabled": ""
    },
    model: {
      value: _vm.form.card_quota,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "card_quota", $$v);
      },
      expression: "form.card_quota"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Display Order (asc)",
      "outlined": ""
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  }), _c('v-switch', {
    attrs: {
      "color": "primary",
      "label": _vm.form.trial ? 'Is Trial of another pass (must provide coupon code). Hidden when user trial once' : 'Is Trial of other pass? (No)'
    },
    model: {
      value: _vm.form.trial,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "trial", $$v);
      },
      expression: "form.trial"
    }
  }), _c('v-switch', {
    attrs: {
      "color": "primary",
      "label": _vm.form.bulk ? 'Is Bulk purchase of another pass (Agent can bulk purchase at discounted prices)' : 'Is Bulk purchase? (No. Instead it is customer pass)'
    },
    model: {
      value: _vm.form.bulk,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bulk", $$v);
      },
      expression: "form.bulk"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.trial || _vm.form.bulk,
      expression: "form.trial || form.bulk"
    }]
  }, [_c('p', [_vm._v("Since the pass is either bulk-purchase (agent) or trial enabled, you must provide a parent pass to tell the system what is the next pass after the first recursion (agent gift period over, trial ends)")]), _c('v-autocomplete', {
    attrs: {
      "label": "Parent Pass",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "rules": [_vm.form.trial || _vm.form.bulk ? _vm.between(_vm.form.parent_id, 1, 99999999) : null],
      "items": _vm.passOptions
    },
    model: {
      value: _vm.form.parent_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parent_id", $$v);
      },
      expression: "form.parent_id"
    }
  })], 1), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Coupon Code (optional)",
      "hint": "This coupon code must exist & be valid: free_trial type for trial, or standard coupon, not expired, included to same SKUs.",
      "outlined": ""
    },
    model: {
      value: _vm.form.coupon_code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "coupon_code", $$v);
      },
      expression: "form.coupon_code"
    }
  }), _c('v-switch', {
    attrs: {
      "color": "primary",
      "label": _vm.form.active ? 'Active' : 'Inactive'
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }